import { getAPI, postAPI, patchAPI } from 'utils/api'
import {
  GET_ACTIVITY_CPD,
  GET_ACTIVITY_CPD_BY_ID,
  CREATE_ACTIVITY_CPD,
  EDIT_ACTIVITY_CPD,
  DELETE_ACTIVITY_CPD,
  CANCEL_ACTIVITY_CPD,
  GET_REGISTERED_NAME_LIST,
  GET_INSTRUCTORS,
} from 'redux/actionTypes'
import { showLoading, hideLoading } from 'redux/actions/loading.js'

import axios from "axios";
import { BASE_API } from '../../configs/app'

export const ApiGetActivityCpdDowload  = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_INSTRUCTORS.REQUEST })

    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }

    const params = {
      $skip: filter.skip,
      $limit: filter.limit,
      '$sort[id]': -1,
    }

    if(filter?.fix_organization_id && filter?.fix_organization_id !== ''){
      params['organization_id'] = filter?.fix_organization_id;
    }else{
      if (filter?.filter_organization_id && filter?.filter_organization_id !== '') {
        params['organization_id'] = filter.filter_organization_id;
      }else{
        params['organization_id[$ne]'] = 94;
      }
      
    }



    

    if (filter?.status && filter?.status !== '') params['status'] = filter.status
    if (filter?.search && filter?.search !== '') {
      params['$or[0][name][$like]'] = `%${filter.search}%`
      params['$or[1][code][$like]'] = `%${filter.search}%`
    }

    if(filter?.date_type && filter?.date_type !== ''){
      params[`${filter.date_type}[$gte]`] = `${filter.start_at}`
      params[`${filter.date_type}[$lte]`] = `${filter.date_end}`
    }

    const data_result = await getAPI({
      url: '/api/events/cms_vw_events_org',
      headers: headers,
      params,
    })

    return { type: 'ApiGetActivityCpdDowload_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetActivityCpdDowload_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const getActivityCpd =
  (filter = { skip: 0, limit: 10 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_ACTIVITY_CPD.REQUEST })
      
      const params = {
        $skip: filter.skip,
        $limit: filter.limit,
        '$sort[id]': -1,
      }

      if(filter?.fix_organization_id && filter?.fix_organization_id !== ''){
        params['organization_id'] = filter?.fix_organization_id;
      }else{
        if (filter?.filter_organization_id && filter?.filter_organization_id !== '') {
          params['organization_id'] = filter.filter_organization_id;
        }else{
          params['organization_id[$ne]'] = 94;
        }
        
      }



      

      if (filter?.status && filter?.status !== '') params['status'] = filter.status
      if (filter?.search && filter?.search !== '') {
        params['$or[0][name][$like]'] = `%${filter.search}%`
        params['$or[1][code][$like]'] = `%${filter.search}%`
      }

      if(filter?.date_type && filter?.date_type !== ''){
        params[`${filter.date_type}[$gte]`] = `${filter.start_at}`
        params[`${filter.date_type}[$lte]`] = `${filter.date_end}`
      }

      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      const data_result = await getAPI({
        url: '/api/events/cms_vw_events_org',
        headers,
        params,
      })
      return dispatch({ type: GET_ACTIVITY_CPD.SUCCESS, data : data_result.data, limit : data_result.limit, skip : data_result.skip, total : data_result.total, data_info: data_result })
      
      //---- New axios
      /*
      const authenticateHeader = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      const Url_api = BASE_API + '/api/events/cms_vw_events_org';
      const data_api_result = await axios.get(Url_api, authenticateHeader,params);
      //console.log(JSON.stringify(data_api_result))
      if(data_api_result.data){
        const data_result = data_api_result.data;
        return dispatch({ type: GET_ACTIVITY_CPD.SUCCESS, data : data_result.data, limit : data_result.limit, skip : data_result.skip, total : data_result.total, data_info: data_result })
      }else{
        return dispatch({
          type: GET_ACTIVITY_CPD.FAILURE,
          error: err,
          showAlert: true,
          alertMessage: 'Get Activity CPD Failure',
        })
      }
      */
      //---- End axios
      
      
    } catch (err) {
      return dispatch({
        type: GET_ACTIVITY_CPD.FAILURE,
        error: err,
        showAlert: true,
        alertMessage: 'Get Activity CPD Failure',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getActivityCpdBYId = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_ACTIVITY_CPD_BY_ID.REQUEST })

    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    const data = {
      event_id: id,
    }

    const { data_info } = await postAPI({
      url: '/api/events/CMSGetEventInfo',
      data,
      headers,
    })

    return dispatch({ type: GET_ACTIVITY_CPD_BY_ID.SUCCESS, data: data_info })
  } catch (err) {
    return dispatch({
      type: GET_ACTIVITY_CPD_BY_ID.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Get Activity CPD By Id Failure',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const createActivityCpd = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_ACTIVITY_CPD.REQUEST })

    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }

    const response = await postAPI({
      url: '/api/events/CMSEvents',
      data,
      headers,
    })

    dispatch({ type: CREATE_ACTIVITY_CPD.SUCCESS })
    return {
      code: response.code,
    }
  } catch (err) {
    return dispatch({
      type: CREATE_ACTIVITY_CPD.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Create Activity CPD Failure',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const editActivityCpd = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: EDIT_ACTIVITY_CPD.REQUEST })

    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }

    await patchAPI({
      url: `/api/events/CMSEvents/${id}`,
      data,
      headers,
    })

    return dispatch({ type: EDIT_ACTIVITY_CPD.SUCCESS })
  } catch (err) {
    return dispatch({
      type: EDIT_ACTIVITY_CPD.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Edit Activity CPD Failure',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const deleteActivityCpd = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DELETE_ACTIVITY_CPD.REQUEST })

    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }

    await patchAPI({
      url: `/api/events/CMSEvents/${id}`,
      data,
      headers,
    })

    return dispatch({ type: DELETE_ACTIVITY_CPD.SUCCESS })
  } catch (err) {
    return dispatch({
      type: DELETE_ACTIVITY_CPD.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Delete Activity CPD Failure',
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getActivityCpd())
  }
}

export const cancelActivityCpd = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CANCEL_ACTIVITY_CPD.REQUEST })

    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }

    await patchAPI({
      url: `/api/events/CMSEvents/${id}`,
      data,
      headers,
    })

    return dispatch({ type: CANCEL_ACTIVITY_CPD.SUCCESS })
  } catch (err) {
    return dispatch({
      type: CANCEL_ACTIVITY_CPD.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Cancel Activity CPD Failure',
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getActivityCpd())
  }
}

export const getRegisteredNameList =
  (
    event_id,
    is_member_coe,
    filter = {
      skip: 0,
      limit: 0,
    }
  ) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_REGISTERED_NAME_LIST.REQUEST })

      const accessToken = localStorage.getItem('accessToken')
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }

      const params = {
        $skip: filter.skip,
        $limit: filter.limit,
        '$sort[id]': 1,
        event_id: event_id,
        is_member_coe,
      }

      if (filter?.name && filter?.name !== '') {
        params['$or[0][user_no][$like]'] = `%${filter.name}%`
        params['$or[1][full_name][$like]'] = `%${filter.name}%`
        params['$or[2][email][$like]'] = `%${filter.name}%`
      }

      const { data, skip, limit, total } = await getAPI({
        url: `/api/events/CMSEvent_participants`,
        headers,
        params,
      })
      if(filter.limit == 10000){ //--- กรณี Dowload Excel
        return { type: 'getRegisteredNameList_SUCCESS', data: data }
      }else{
        return dispatch({ type: GET_REGISTERED_NAME_LIST.SUCCESS, data, skip, limit, total, is_member_coe })
      }

    } catch (err) {
      return dispatch({
        type: GET_REGISTERED_NAME_LIST.FAILURE,
        error: err,
        showAlert: true,
        alertMessage: 'Get Registered name list Failure',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getInstructorNameList =
  (event_id, { filter = { skip: 0, limit: 10 } }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_INSTRUCTORS.REQUEST })

      const accessToken = localStorage.getItem('accessToken')
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }

      const params = {
        $skip: filter.skip,
        $limit: filter.limit,
        '$sort[id]': 1,
        event_id,
      }

      const { data, skip, limit, total } = await getAPI({
        url: `/api/events/CMSEvent_instructors`,
        headers,
        params,
      })

      return dispatch({ type: GET_INSTRUCTORS.SUCCESS, data, skip, limit, total })
    } catch (err) {
      return dispatch({
        type: GET_INSTRUCTORS.FAILURE,
        error: err,
        showAlert: true,
        alertMessage: 'Get Instructor name list Failure',
      })
    } finally {
      dispatch(hideLoading())
    }
  }
