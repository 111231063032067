import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams, useNavigate, NavLink } from 'react-router-dom'
import XLSX from "xlsx";

import {  getAdminUser,GetPermissionOther } from '../../utils/common'
import { ApiGetOrgList,ApiUpdateOrg } from '../../redux/actions/organizations'
import { ApiGetOrgType } from '../../redux/actions/master'

import SearchText from '../../components/form/SearchText'
import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import TableV2 from '../../components/common/TableV2'
import Field from '../../components/form/Field'
import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'
import RadioButton from '../../components/form/RadioButton'
import SidePanel from '../../components/common/SidePanel'
import EditUserAccount from '../../pages/organization/EditUserAccount'
import NewUserAccount from '../../pages/organization/NewUserAccount'
import SwitchButton from '../../components/form/SwitchButton'


import iconPlus from '../../assets/images/icon-plus.svg'
import iconEdit from '../../assets/images/edit-icon.png'
import iconDelete from '../../assets/images/icon-bin-red.svg'
import { FaBullseye } from 'react-icons/fa'
import { GrStatusGoodSmall } from 'react-icons/gr'
import { BsCheck2Circle } from 'react-icons/bs'
import warningIcon from '../../assets/images/warning-icon.png'
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'


const Div = styled.div`

  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Base-Font-Title-Color);
  }
  .content-title-text {
    color: var(--Base-Font-Title-SUB-Color);
    font-size: 18px;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
  }
  .css_magin_top_16 {
    margin-top: 16px;
  }
  .content-fillter-bg {
    min-height: 68px;
    background-color: var(--Gray-50);
    border-radius: 8px;
    margin-bottom: 21px;
    padding: 10px;
  }
  .content-fillter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .search-wrapper {
      margin-right: 12px;
    }

    .filter-list {
      flex-basis: 100%;
      margin-top: 24px;
    }

    .group_filter {
      display: flex;
      align-items: center;
    }
  }

  .table-wrapper {
    margin-top: 24px;
    border: 1px solid #eaecf0;
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    background-color: #ffffff;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Table-Bg-Header);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--BADGE-Table-Font);
        background: var(--BADGE-Table-Bg);
        border-radius: 16px;
        border: 1px var(--BADGE-Table-Border) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }
  .cursor_pointer {
    cursor: pointer;
  }
  .css_magin_top_10 {
    margin-top: 10px;
  }
  .css_filter_name {
    min-width: 450px;
    margin-right: 10px;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .css_button_filter{
    display: inline-block;
    align-self: flex-end;
  }
  .button_create {
    color: #ffffff;
    background-color: #17b26a;
    border: 1px solid #17b26a;
  }
  .button_request_approve {
    color: #D21404;
    background-color: #ffffff;
    border: 1px solid #FDA29B;
  }
  .status-badge {
    /*width: 150px;*/
    /*padding: 0.2rem;*/
    width: fit-content;
    padding: 2px 6px;
    padding: 0.2rem;
    text-align: center;
    border: 1.5px solid var(--Gray-300);
    border-radius: 6px;
    font-weight: 500;

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: #17b26a;

      &.deny {
        color: red;
      }
      &.norequest {
        color: #7f56d9;
      }
      &.pedding{
        color: #475467;
      }
    }
  }
  .accessRequest-active{
    font-size: 20px;
    color: #17b26a;
  }
  .accessRequest-notactive{
    font-size: 20px;
    color: #475467;
  }
  .text-bold {
    font-weight: bold;
  }
  
  .link {
    color: var(--Td-Font-Color-Link);
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .user_account{
    padding: 10px 0px;
  }
  .border_top{
    border-top: 1px solid #eaecf0;
  }
`

const DivTab = styled.div`
width: 100%;
margin-bottom: 1rem;
margin-top: 1rem;
display: flex;
/*border-bottom: 1px solid var(--Tab-Border-Default);*/

.btn-menu-style {
    border: none;
    padding: 10px 18px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    background-color: var(--Tab-Bg-Default);
    /*border-bottom: 0px solid var(--Tab-Border-Default);*/
    border-bottom: 2px solid var(--Tab-Border-Default);
    color: var(--Tab-Font-Default);  
    font-size: 14px;
    /*font-family: 'Inter','Noto Sans Thai';*/
    font-family: inherit;
}

.menu-active {
    border: none;
    padding: 10px 18px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 2px solid var(--Tab-Border-Current) !important;
    color: var(--Tab-Font-Current); 
    background-color: var(--Tab-Bg-Current);
    /*font-family: 'Inter','Noto Sans Thai';*/
    font-family: inherit;
}
`



const OrgList = () => {
  const dispatch = useDispatch()
  const user = getAdminUser()
  const navigate = useNavigate()

  const permission_CpdManageOrgList_Edit = GetPermissionOther('CpdManageOrgList','CpdManageOrgList_Edit');
  const permission_CpdManageOrgList_Del = GetPermissionOther('CpdManageOrgList','CpdManageOrgList_Del');

  const [OrgId, setOrgId] = useState(null)
  const [filterName, setfilterName] = useState('')
  const [filterOrgType, setfilterOrgType] = useState('')
  const [org_type_option, setorg_type_option] = useState([])
  useEffect(() => {
    dispatch(ApiGetOrgType()).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const neworg_type_option= data_info?.data?.map((v) => ({
                text: v?.name_th_th,
                value: v?.id,
            }))
            setorg_type_option([...neworg_type_option])
        }
    })
  }, [dispatch])

  const initLangRadioOptionList = [
    {
      text: '',
      value: true,
    },
  ]

  const [tabactive, setTabactive] = useState('300')
  const [menuTabList, setMenuTabList] = useState([
    {
        name: 'อนุมัติ',
        value: '300',
        active_status: true,
    },
    {
        name: 'รอตรวจเอกสาร',
        value: '100',
        active_status: false,
    },
    {
      name: 'ขอเอกสารเพิ่มเติม',
      value: '101',
      active_status: false,
    },
    {
      name: 'รอผลพิจารณาจากคณะอนุกรรมการ',
      value: '200',
      active_status: false,
    },
    {
      name: 'รอนัดตรวจประเมิน',
      value: '400',
      active_status: false,
    },
    {
      name: 'รอลงผลตรวจประเมิน',
      value: '500',
      active_status: false,
    },
    {
      name: 'ไม่อนุมัติ',
      value: '999',
      active_status: false,
    },
    {
      name: 'ทั้งหมด',
      value: 'all',
      active_status: false,
    },
  ])

  const handleClickTaskMenuTab = (data) => {
    let tmpList = [...menuTabList]
    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i].value == data.value) {
        tmpList[i].active_status = true
        setTabactive(tmpList[i].value)

        setfilterName('')
        setfilterOrgType('')
        const filter = {
          skip: 0,
          limit: limit_data,
          tab_active : tmpList[i].value
        }
        fetchData(filter)
        
        setPage(1)

      } else {
        tmpList[i].active_status = false
      }
    }
    setMenuTabList([...tmpList])
  }


  // modal
  
  const [ModalNewUser, setModalNewUser] = useState(false)
  const [ModalEditUser, setModalEditUser] = useState(false)
  const [DataEdit, setDataEdit] = useState('')

  const [ModalCreate, setModalCreate] = useState(false)
  const [ModalConfirmDel, setModalConfirmDel] = useState(false)
  const [ModalEditData, setModalEditData] = useState(false)
  const [ModalViewActionPlan, setModalViewActionPlan] = useState(false)
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const [ModalConfirmDialogDel, setModalConfirmDialogDel] = useState(false)
  const [ConfirmMessageModal, setConfirmMessageModal] = useState(initMessageModal)

  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

  const limit_data = 10;
  const [total, settotal] = useState(0)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [TableCellData, setTableCellData] = useState([])
  const TableColumnData = [
    {
      Header: 'ลำดับ',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'ชื่อองค์กรแม่ข่าย',
      accessor: 'name_th',
      disableSortBy: false,
    },
    {
      Header: 'เลขประจำตัวผู้เสียภาษี',
      accessor: 'tin',
      disableSortBy: false,
    },
    {
      Header: 'ประเภทองค์กร',
      accessor: 'org_type_formatted',
      disableSortBy: false,
    },
    {
      Header: 'รหัสองค์กร',
      accessor: 'code',
      disableSortBy: false,
    },
    /*
    {
      Header: 'ผู้ดูแล',
      accessor: 'user_account_data',
      disableSortBy: false,
    },
    */
    {
      Header: 'เบอร์โทรศัพท์องค์กร',
      accessor: 'phone_no',
      disableSortBy: false,
    },
    {
      Header: 'ชื่อผู้ดูแล',
      accessor: 'main_user_fullname',
      disableSortBy: false,
    },
    {
      Header: 'email',
      accessor: 'main_user_email',
      disableSortBy: false,
    },
    {
      Header: 'สถานะผู้ดูแล',
      accessor: 'status_org_user_account_name',
      disableSortBy: false,
    },
    {
        Header: 'วันที่สมัครแม่ข่าย',
        accessor: 'submitted_at_display',
        disableSortBy: false,
    },
    {
        Header: 'วันที่หมดอายุ',
        accessor: 'expiring_at_display',
        disableSortBy: false,
    },
    {
      Header: 'สถานะ',
      accessor: 'status_name',
      disableSortBy: false,
    },
    {
      accessor: 'buttonDelete',
      disableSortBy: true,
    }
  ]

  const fetchData = useCallback(
    async (filter) => {
      dispatch(ApiGetOrgList(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          if (data_info.total > 0) {
            const totalPage = Math.ceil(data_info.total / limit_data)
            settotal(data_info.total)
            setTotalPages(totalPage);
            const newTableCellData = data_info.data.map((v, index) => ({
              no: limit_data * page - (9 - index),
              name_th: permission_CpdManageOrgList_Edit ?.canRead === true ? (
                <div className="text-bold link" onClick={() => EditData(v.id)}>
                  {v.name_th}
                </div>
              ) : (<div className="text-bold">
              {v.name_th}
            </div>),
              tin: v.tin ? v.tin : '-',
              org_type_formatted: v.org_type_formatted ? v.org_type_formatted : '-',
              code: v.code ? v.code : '-', 
              phone_no: v.phone_no ? v.phone_no : '-',             
              main_user_fullname: v.main_user_fullname ? v.main_user_fullname : '-',
              main_user_email: v.main_user_email ? v.main_user_email : '-',
              status_org_user_account_name: v.status_org_user_account_name ? v.status_org_user_account_name : '-',
              /*
              user_account_data : v.user_account_data ? (
                <div>
                  {v.user_account_data?.map((v_user, index_user) => (
                    <div className={`user_account ${index_user > 0 ? 'border_top' : ''}`}>
                      <div>ชื่อ : {v_user.main_user_fullname}</div>
                      <div>Email : {v_user.main_user_email}</div>
                      <div>สถานะ : {v_user.status_org_user_account_name}</div>
                    </div>
                  ))}   
                </div>
              ) : (<div>-</div>),
              */
              submitted_at_display: v.submitted_at_display ? v.submitted_at_display : '-',
              expiring_at_display: v.expiring_at_display ? v.expiring_at_display : '-',   
              status_name: v.statustext ? (
                <div className="status-badge">
                  <GrStatusGoodSmall className={`status ${v.applications_status == 999 ? 'deny' : ''}`} />
                  {v.statustext}
                </div>
              ) : (
                <div className="status-badge">
                  <GrStatusGoodSmall className={`status norequest`} />
                  {v.statustext ? v.statustext : 'ยังไม่ยื่น' }
                </div>
              ),
              buttonDelete: permission_CpdManageOrgList_Del?.canRead === true ? <img className="cursor_pointer" src={iconDelete} title="Delete" onClick={() => DelData(v.id)} /> : '',
            }))
            setTableCellData(newTableCellData)
          }else{
            settotal(0);
            setPage(0);
            setTotalPages(0);
            setTableCellData([]);
          }
         
        }
      })
    },
    [dispatch]
  )

  useEffect(() => {
    const filter = {
      skip: 0,
      limit: limit_data,
      tab_active : tabactive
    }
    fetchData(filter)
  }, [fetchData, limit_data])

  const onChangePaginationAttendeeAttend = async (value) => {
    setPage(value.page)
    const filter = {
      skip: (value.page  - 1) * limit_data, 
      limit: limit_data,
      name : filterName,
      org_type : filterOrgType,
      tab_active : tabactive
    }
    fetchData(filter)
  }
  const onClearfilterName = () => {
    setfilterName('');
  }
  const onSearch = () => {
    const filter = {
      skip: 0,
      limit: limit_data,
      name : filterName,
      org_type : filterOrgType,
      tab_active : tabactive
    }
    fetchData(filter)
    setPage(1)
  }
  const onClearSearch = () => {
    setfilterName('')
    setfilterOrgType('')
    const filter = {
      skip: 0,
      limit: limit_data,
      tab_active : tabactive
    }
    fetchData(filter)
    
    setPage(1)
  }

  const DelData = (data) => {
    setOrgId(data)
    setConfirmMessageModal({
        headline: 'กรุณายืนยันการลบ',
        message: '',
    })
    setModalConfirmDialogDel(true);
  }

  const EditData = useCallback(
    (id) => {
      navigate(`/CpdManage/OrgProfile/${id}`)
    },
    [dispatch]
  )

  const OpenCreateModal = () => {
    setModalNewUser(true)
  }

  const handleApproveRequest = (type_approve_id) => {
    setModalConfirmDialog(false)
    

    const request = {
      is_active: false ,
  }
  dispatch(ApiUpdateOrg(OrgId, request)).then(({ type,data_info }) => {
    
    if(type.endsWith('_SUCCESS')){
      setSuccessMessageModal({
        headline: 'ลบรายการสำเร็จ',
        message: '',
      })
      setSuccessModal(true)
      /*
      if(data_info.code == 200){
        setSuccessMessageModal({
          headline: 'ลบรายการสำเร็จ',
          message: '',
        })
        setSuccessModal(true)
      }else{
        
          setFailMessageModal({
              headline: data_info.message,
              message: '',
          })
          setFailModal(true)
        
      }
      */
    }else{
      setFailMessageModal({
        headline: 'internal server error',
        message: '',
      })
      setFailModal(true)
    }
    
  })

    
  }

  const loadExcel = () => {
    //alert('loadExcel')
    const filter = {
      skip: 0,
      limit: 10000,
      tab_active : tabactive
    }
    dispatch(ApiGetOrgList(filter)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if (data_info.total > 0) {
          const resultToxlsx = data_info.data.map((v,index) => {
              return {
                  "ลำดับ" : index + 1,
                  "ชื่อองค์กรแม่ข่าย" : v.name_th ? v.name_th : '-',
                  "เลขประจำตัวผู้เสียภาษี" : v.tin ? v.tin : '-',
                  "ประเภทองค์กร" : v.org_type_formatted ? v.org_type_formatted : '-',
                  "รหัสองค์กร" : v.code ? v.code : '-',
                  "เบอร์โทรศัพท์องค์กร" : v.phone_no ? v.phone_no : '-',
                  "ชื่อผู้ดูแล" : v.main_user_fullname ? v.main_user_fullname : '-',
                  "email" : v.main_user_email ? v.main_user_email : '-',
                  "สถานะผู้ดูแล	" : v.status_org_user_account_name ? v.status_org_user_account_name : '-',
                  "วันที่สมัครแม่ข่าย" : v.submitted_at_display ? v.submitted_at_display : '-',
                  "วันที่หมดอายุ" : v.expiring_at_display ? v.expiring_at_display : '-',
                  "สถานะ" : v.statustext ? v.statustext : 'ยังไม่ยื่น',
              };
          });
          const dataWS = XLSX.utils.json_to_sheet(resultToxlsx);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, dataWS);
          XLSX.writeFile(wb, "รายชื่อองค์กรแม่ข่าย.xlsx");
        }
      }
    })
  }


  return (
    <Div>
      <header>
        <div className="content-title">
            รายชื่อองค์กรแม่ข่าย
        </div>
        <div className="content-title-text">
            สำหรับเจ้าหน้าที่บริหารจัดการข้อมูลขององค์กรแม่ข่าย
        </div>
        <div className="border_header"></div>
        
        <DivTab>
          {menuTabList?.map((item, index) => (
              <div
              id={'this_name-' + item.value}
              key={'btn-' + index}
              className={item.active_status ? 'menu-active' : 'btn-menu-style'}
              onClick={() => handleClickTaskMenuTab(item)}
              >
                  <div className="box_sub_menu">
                      <div>
                          {item.name}
                      </div>
                  </div>
              
              </div>
          ))}
        </DivTab>
        <div className="css_magin_top_16"></div>
        <div className="content-fillter-bg">
          <div className="content-fillter">
            <div className="group_filter">
              <div className="css_filter_name">
                <Field className="field" label="ค้นหาด้วยชื่อหรือเลขประจำตัวผู้เสียภาษี">
                  <SearchText
                    placeholder="ค้นหาด้วยชื่อหรือเลขประจำตัวผู้เสียภาษี"
                    value={filterName}
                    onChange={(e) => setfilterName(e.target.value)}
                    onClear={onClearfilterName}
                  />
                </Field>
              </div>
              <div className="mr-1-rem">
                <Field className="field" label="ประเภทองค์กร">
                  <Dropdown
                      id={`dd_filterOrgTypes`}
                      className="dd_filterOrgType"
                      value={filterOrgType}
                      optionList={org_type_option}
                      onChange={(v) => setfilterOrgType(v)}
                      placeHolder={'เลือกประเภทองค์กร'}
                  />
                </Field>
              </div>
              <div className="css_button_filter mr-1-rem">
                  <Button onClick={onSearch} >
                    ค้นหา
                  </Button>
              </div>
              <div className="css_button_filter mr-1-rem">
                <ButtonOutline onClick={onClearSearch} >
                  ล้างการค้นหา
                </ButtonOutline>
              </div>
            </div>
          </div>
        </div>

        <div className="table-wrapper">
          <div className="table-header">
            <div className="group">
              <div className="table-title">รายชื่อองค์กรแม่ข่ายทั้งหมด</div>
              <div className="table-total">
                {total} รายการ
              </div>
            </div>
            <div>
              <ButtonOutline onClick={() => loadExcel()}>Download</ButtonOutline>
            </div>
          </div>
          <div className="css_magin_top_10">
            <TableV2
              columns={TableColumnData}
              data={TableCellData}
              onStateChange={onChangePaginationAttendeeAttend}
              pageCount={totalPages}
              page={page}
              loading={false}
            />
          </div>
        </div>
      </header>
      
      <SidePanel isOpen={ModalNewUser} setIsOpen={setModalNewUser} width={400}>
            <NewUserAccount 
              width={400} 
              onClose={() => setModalNewUser(false)} 
              onSubmit={() => {
                setModalNewUser(false)
                onClearSearch()
              }}
            />
      </SidePanel>

      <SidePanel isOpen={ModalEditUser} setIsOpen={setModalEditUser} width={400}>
            <EditUserAccount 
              width={400} 
              onClose={() => setModalEditUser(false)} 
              onSubmit={() => {
                setModalEditUser(false)
                onClearSearch()
              }}
              DataEdit={DataEdit} 
            />
      </SidePanel>

      <DialogConfirmV2
        open={Boolean(ModalConfirmDialog)}
        onClose={() => setModalConfirmDialog(false)}
        onNo={() => handleApproveRequest(2)}
        onSubmit={() => handleApproveRequest(1)}
        icon={warningIcon}
        title={ConfirmMessageModal.headline}
        nameItem={ConfirmMessageModal.message}
        textYes='อนุมัติ'
        textNo='ปฏิเสธ'
      />

      <DialogConfirmV2
        open={Boolean(ModalConfirmDialogDel)}
        onClose={() => setModalConfirmDialogDel(false)}
        onNo={() => setModalConfirmDialogDel(false)}
        onSubmit={() => {
          setModalConfirmDialogDel(false)
          handleApproveRequest(0)
        }}
        icon={warningIcon}
        title={ConfirmMessageModal.headline}
        nameItem={ConfirmMessageModal.message}
        textYes='ยืนยัน'
        textNo='ยกเลิก'
      />

      {/* Dialog */}
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={() => {
          setSuccessModal(false)
          onClearSearch()
        }}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes='ตกลง'
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => {
          setFailModal(false)
          onClearSearch()
        }}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes='ตกลง'
      />

    </Div>
  )
}

export default OrgList
