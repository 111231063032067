import React, { useState, useEffect, useCallback } from 'react'
import { useParams,useNavigate } from 'react-router-dom'
import { DateDisplay,DateTimeHHmmssDisplay,GetPermissionOther } from 'utils/common'
import XLSX from "xlsx";

import { useDispatch, useSelector } from 'react-redux'
import { selectActivityCPD } from 'redux/selectors'
import { getRegisteredNameList } from 'redux/actions/activityCpd'
import {ApiExternalEventSentEmail,ApiUpdateUserActivityPoint } from 'redux/actions/events'
import {
  getMemberCOE,
} from 'redux/actions/master'

import styled from 'styled-components'
import Switchs from 'components/form/Switchs'
import { Box, Stack } from '@mui/material'
import Date from 'components/form/Date'
import TabGroupMenu from 'components/containers/TabGroupMenu'
import Table from 'components/common/Table'
import Button from 'components/form/button/Button'
import ButtonOutline from 'components/form/button/ButtonOutline'
import Badge from 'components/containers/Badge'
import RadioButton from 'components/form/RadioButton'
import DialogConfirmV2 from 'components/dialog/DialogConfirmV2'
import DialogSuccess from 'components/dialog/DialogSuccess'
import DialogFail from 'components/dialog/DialogFail'
import SearchText from 'components/form/SearchText'
import Field from 'components/form/Field'
import ButtonSecondary from 'components/form/button/ButtonSecondary'

import failIcon from 'assets/images/fail-icon.png'
import successIcon from 'assets/images/success-icon.png'
import warningIcon from 'assets/images/warning-icon.png'
import { LiaCheckSolid, LiaTimesSolid } from 'react-icons/lia'

const Div = styled.div`
  .input-heading {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .badge-type-join-style {
    &.join {
      border-color: var(--success-200);
      background: var(--success-50);
      color: var(--success-700);
    }
    &.dont-join {
      border-color: var(--Error-200);
      background: var(--Error-50);
      color: var(--Error-700);
    }
    &.dont-scan {
      border-color: var(--Gray-200);
      background: var(--Gray-50);
      color: var(--Gray-700);
    }
  }
  .disable_compare{
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    background: #D0D5DD;
    padding: 2px 18px;
    color: #000000;
    font-size: 12px;
    text-align: center;
    .font_bold{
      font-weight: 600;
    }
  }
      .box_radio{
    padding: 0px 24px;
    display: flex;
    gap : 0px;
    .radio_css{
        display: flex;
        align-items: center;
    }
    .text_green{
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color : #085D3A;
    }
    .text_red{
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color : #912018;
    }
    .text_normal{
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color : #101828;
    }
}

    .group_filter {
      display: flex;
      align-items: end;
      gap : 10px;
    }
`
const TableWrapper = styled.div`
  .header-table {
    padding: 26px 24px;
    border-radius: 8px 8px 0 0;
    border: 1px solid var(--Gray-200);
  }
  .heading-table {
    color: var(--Gray-900, #101828);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
  }
  .sub-heading-table {
    color: var(--Gray-600);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
  }
`

function RegisteredNameListPart() {
  // external hook
  const dispatch = useDispatch()
  const { id } = useParams()
  const navigate = useNavigate()

  // tab menu
  const [filterName, setfilterName] = useState('')
  const [DataResentMail, setDataResentMail] = useState({
    user_activity_point_id : null,
    full_name : null,
    member_no : null,
    email : null
  })
  const [IsModalConfirmMail, setIsModalConfirmMail] = useState(false)

  const [successModal, setSuccessModal] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState({
    headline: 'ทำรายการสำเร็จ',
    message: '',
  })
  const [failMessageModal, setFailMessageModal] = useState({
    headline: 'ทำรายการไม่สำเร็จ',
    message: '',
  })
  const [failModal, setFailModal] = useState(false)

  const initialTabMenuOptions = [
    {
      text: 'ผู้ลงทะเบียน (สมาชิกสภาวิศวกร)',
      value: 'list-of-registered-member',
    },
    {
      text: 'ผู้ลงทะเบียน (บุคคลทั่วไป)',
      value: 'list-of-registered-normal',
    },
  ]
  const [filterDate, setfilterDate] = useState(null)
  const { totalCoEMember, totalNormalMember } = useSelector(selectActivityCPD)
  const [tabMenuOptions, setTabMenuOptions] = useState([])
  const [tabMenu, setTabMenu] = useState('list-of-registered-member')

  const toggleTab = (value) => {
    setTabMenu(value)
    setPage(1)
  }
  const fetchRegisteredNameList = useCallback(
    (id, is_member_coe, filter = { skip: 0, limit: 10 }) => {
      dispatch(getRegisteredNameList(id, is_member_coe, filter))
    },
    [dispatch]
  )

  useEffect(() => {
    if (tabMenu === 'list-of-registered-member') fetchRegisteredNameList(id, true)
    else fetchRegisteredNameList(id, false)
  }, [tabMenu, id])
  useEffect(() => {
    const newTabMenuOptions = initialTabMenuOptions.map((v) => ({
      ...v,
      number: v.value === 'list-of-registered-member' ? totalCoEMember : totalNormalMember,
    }))
    setTabMenuOptions(newTabMenuOptions)
  }, [totalCoEMember, totalNormalMember])

  // page,pageCount
  const { skip, limit, total } = useSelector(selectActivityCPD)
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)

  useEffect(() => {
    setPageCount(Math.ceil(total / limit))
  }, [limit, total, skip])


  const [coeMemberColumns, setcoeMemberColumns] = useState([
    {
      Header: 'ลำดับ',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'เลขที่สมาชิก',
      accessor: 'user_no',
      disableSortBy: false,
    },
    {
      Header: 'ชื่อ นามสกุล',
      accessor: 'full_name',
      disableSortBy: false,
    },
    {
      Header: 'วันที่ลงทะเบียนล่วงหน้า',
      accessor: 'preregister_date',
      disableSortBy: false,
    },
    {
      Header: 'การเข้าร่วมกิจกรรม',
      accessor: 'status_name',
      disableSortBy: false,
    },
    {
      Header: 'วันที่เข้าร่วมกิจกรรมวันแรก',
      accessor: 'date_check_in',
      disableSortBy: false,
    },
    {
      Header: 'วันที่ Checkout วันแรก',
      accessor: 'checkout_date',
      disableSortBy: false,
    },
    {
      Header: 'วันที่เริ่มนับคะแนน',
      accessor: 'effective_date',
      disableSortBy: false,
    },
    {
      Header: 'วันที่นําเข้าข้อมูล',
      accessor: 'createdAt',
      disableSortBy: false,
    },
    {
      Header: 'จำนวนชั่วโมง',
      accessor: 'received_hours',
      disableSortBy: false,
    },
    {
      Header: 'คะแนน CPD ที่ได้รับ',
      accessor: 'point',
      disableSortBy: false,
    },
    {
      Header: 'สอบผ่าน/ไม่ผ่าน',
      accessor: 'is_active_exam',
      disableSortBy: false,
    },
    {
      Header: 'หมายเหตุ',
      accessor: 'remark',
      disableSortBy: false,
    },
  ])
  const { activityInfo } = useSelector(selectActivityCPD)
  const [isOpenCompareUser, setisOpenCompareUser] = useState(false)

  useEffect(() => {
    if (activityInfo.id) {
      //console.log(activityInfo)
      setisOpenCompareUser(activityInfo.is_open_compareuser)
      setfilterDate(activityInfo.start_at)
      if(activityInfo.is_email_confirm){
        setcoeMemberColumns(
          [
            {
              Header: 'ลำดับ',
              accessor: 'no',
              disableSortBy: false,
            },
            {
              Header: 'เลขที่สมาชิก',
              accessor: 'user_no',
              disableSortBy: false,
            },
            {
              Header: 'ชื่อ นามสกุล',
              accessor: 'full_name',
              disableSortBy: false,
            },
            {
              Header: 'ชื่อหน่วยงาน',
              accessor: 'user_organization_name',
              disableSortBy: false,
            },
            {
              Header: 'วันที่ลงทะเบียน',
              accessor: 'createdAt',
              disableSortBy: false,
            },
            {
              Header: 'การเข้าร่วมกิจกรรม',
              accessor: 'status_name',
              disableSortBy: false,
            },
            {
              Header: 'วันที่เข้าร่วมกิจกรรมวันแรก',
              accessor: 'date_check_in',
              disableSortBy: false,
            },
            {
              Header: 'วันที่ Checkout วันแรก',
              accessor: 'checkout_date',
              disableSortBy: false,
            },
            {
              Header: 'จำนวนชั่วโมง',
              accessor: 'received_hours',
              disableSortBy: false,
            },
            {
              Header: 'คะแนน CPD ที่ได้รับ',
              accessor: 'point',
              disableSortBy: false,
            },
            {
              Header: 'สอบผ่าน/ไม่ผ่าน',
              accessor: 'is_active_exam',
              disableSortBy: false,
            },
            {
              Header: 'Email',
              accessor: 'email',
              disableSortBy: false,
            },
            {
              Header: 'หมายเหตุ',
              accessor: 'remark',
              disableSortBy: false,
            },
            {
              Header: 'วันที่ยืนยัน Email',
              accessor: 'date_approve_email',
              disableSortBy: false,
            },
          ]
        )
      }else if(activityInfo.is_open_email_varification){ //---- กรณีของแม่ข่ายสภาวิศวกร
        setcoeMemberColumns(
          [
            {
              Header: 'ลำดับ',
              accessor: 'no',
              disableSortBy: false,
            },
            {
              Header: 'เลขที่สมาชิก',
              accessor: 'user_no',
              disableSortBy: false,
            },
            {
              Header: 'ชื่อ นามสกุล',
              accessor: 'full_name',
              disableSortBy: false,
            },
            {
              Header: 'ชื่อหน่วยงาน',
              accessor: 'user_organization_name',
              disableSortBy: false,
            },
            {
              Header: 'วันที่ลงทะเบียนล่วงหน้า',
              accessor: 'preregister_date',
              disableSortBy: false,
            },
            {
              Header: 'การเข้าร่วมกิจกรรม',
              accessor: 'status_name',
              disableSortBy: false,
            },
            {
              Header: 'วันที่เข้าร่วมกิจกรรมวันแรก',
              accessor: 'date_check_in',
              disableSortBy: false,
            },
            {
              Header: 'วันที่ Checkout วันแรก',
              accessor: 'checkout_date',
              disableSortBy: false,
            },
            {
              Header: 'วันที่เริ่มนับคะแนน',
              accessor: 'effective_date',
              disableSortBy: false,
            },
            {
              Header: 'วันที่นําเข้าข้อมูล',
              accessor: 'createdAt',
              disableSortBy: false,
            },
            {
              Header: 'จำนวนชั่วโมง',
              accessor: 'received_hours',
              disableSortBy: false,
            },
            {
              Header: 'คะแนน CPD ที่ได้รับ',
              accessor: 'point',
              disableSortBy: false,
            },
            {
              Header: 'สอบผ่าน/ไม่ผ่าน',
              accessor: 'is_active_exam',
              disableSortBy: false,
            },
            {
              Header: 'หมายเหตุ',
              accessor: 'remark',
              disableSortBy: false,
            },
          ]
        )
      }
    }
  }, [activityInfo])

  const permission_CpdManageOrgActivity_Dowload_UserRegister = GetPermissionOther('CpdManageOrgActivity_COE','CpdManageOrgActivity_COE_Dowload_UserRegister');

  // column

  const normalMemberColumn = [
    {
      Header: 'ลำดับ',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'ชื่อ นามสกุล',
      accessor: 'full_name',
      disableSortBy: false,
    },
    {
      Header: 'ชื่อหน่วยงาน',
      accessor: 'user_organization_name',
      disableSortBy: false,
    },
    {
      Header: 'เบอร์โทรศัพท์',
      accessor: 'phone_no',
      disableSortBy: false,
    },
    {
      Header: 'Email',
      accessor: 'email',
      disableSortBy: false,
    },
    {
      Header: 'วันที่ลงทะเบียนล่วงหน้า',
      accessor: 'preregister_date',
      disableSortBy: false,
    },
    {
      Header: 'การเข้าร่วมกิจกรรม',
      accessor: 'status_name',
      disableSortBy: false,
    },
    {
      Header: 'วันที่เข้าร่วมกิจกรรมวันแรก',
      accessor: 'date_check_in',
      disableSortBy: false,
    },
    {
      Header: 'วันที่ Checkout วันแรก',
      accessor: 'checkout_date',
      disableSortBy: false,
    },
    {
      Header: 'วันที่นําเข้าข้อมูล',
      accessor: 'createdAt',
      disableSortBy: false,
    },
    {
      Header: 'จำนวนชั่วโมง',
      accessor: 'received_hours',
      disableSortBy: false,
    },
  ]


  // registerd names
  const { registeredNameList } = useSelector(selectActivityCPD)
  const [registeredNames, setRegisteredNames] = useState([])
  const [registeredNamesForm, setRegisteredNamesForm] = useState([])

  const onChangeSwitch = (user_activity_point_id, event) => {
    //const { checked } = event.target
    setRegisteredNamesForm((prev) =>
      prev.map((v) => {
        if (v.id === id)
          return {
            ...v,
            is_exam : event.target.value == 'Y' ? 'ผ่าน' : 'ไม่ผ่าน',
            is_exam_radio: event.target.value,
          }
        else
          return {
            ...v,
          }
      })
    )
    const request = {
      is_exam_radio: event.target.value,
    }
    dispatch(ApiUpdateUserActivityPoint(user_activity_point_id, request)).then(({ type,data_info }) => { 
      if(type.endsWith('_SUCCESS')){
        if (tabMenu === 'list-of-registered-member'){
          fetchRegisteredNameList(id, true)
        }else{
          fetchRegisteredNameList(id, false)
        } 
      }
    });

  }

  useEffect(() => {
    setRegisteredNamesForm(registeredNameList)
  }, [registeredNameList])

  useEffect(() => {
    const newRegisteredNames = registeredNamesForm.map((v, i) => ({
      ...v,
      no: skip + 1 + i,
      createdAt: DateTimeHHmmssDisplay(v.createdAt),
      date_check_in: v.date_check_in ? DateTimeHHmmssDisplay(v.date_check_in) : '-',
      checkout_date: v.checkout_date ? DateTimeHHmmssDisplay(v.checkout_date) : '-',
      preregister_date: v.preregister_date ? DateTimeHHmmssDisplay(v.preregister_date) : '-',
      effective_date: v.effective_date ? DateTimeHHmmssDisplay(v.effective_date) : '-',
      /*is_active_exam: v.is_exam ? v.is_exam : '-',*/
      /*
      is_active_exam: v.is_exam ? (
        <Switchs
          textIsChecked="ผ่าน"
          textIsNotChecked="ไม่ผ่าน"
          checked={v.is_exam}
          onChangeSwitch={(event) => onChangeSwitch(v.id, event)}
        />
      ) : (<div></div>),
      */
      is_active_exam: v.is_exam_radio ? (
        <div className="box_radio">
            <div className="radio_css">
              <div>
                  <RadioButton 
                      optionList={[{text : 'ผ่าน',value: 'Y'}]} 
                      value={v.is_exam_radio} 
                      handleChange={(e) => onChangeSwitch(v.id, e)}
                  /> 
              </div>
            </div>
            <div className="radio_css">
              <div>
                  <RadioButton 
                      optionList={[{text : 'ไม่ผ่าน',value: 'N'}]} 
                      value={v.is_exam_radio} 
                      handleChange={(e) => onChangeSwitch(v.id, e)}
                  /> 
              </div>
            </div>
        </div>
      ) : (<div></div>),
      status_name: (
        <Badge
          className={`badge-type-join-style ${
            v.status_name === 'เข้าร่วม' ? 'join' : v.status_name === 'ไม่เข้าร่วม' ? 'dont-join' : 'dont-scan'
          }`}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            {v.status_name === 'เข้าร่วม' ? (
              <LiaCheckSolid />
            ) : v.status_name === 'ไม่เข้าร่วม' ? (
              <LiaTimesSolid />
            ) : (
              ''
            )}{' '}
            <Box>{v.status_name}</Box>
          </Stack>
        </Badge>
      ),
      email: v.email ? v.email : '-',
      remark: v.remark,
      date_approve_email: v.date_approve_email ? DateTimeHHmmssDisplay(v.date_approve_email) : (<div><ButtonOutline onClick={() => ConfirmResentMail(v)}>Resend</ButtonOutline></div>),
    }))

    setRegisteredNames(newRegisteredNames)
  }, [registeredNamesForm])

  // pagination
  const onChangePagination = (value) => {
    setPage(value.page)

    const filters = {
      skip: (value.page - 1) * limit,
      limit,
    }

    if (tabMenu === 'list-of-registered-member') fetchRegisteredNameList(id, true, filters)
    else fetchRegisteredNameList(id, false, filters)
  }

  const onSearch = () => {
    const filters = {
      skip: 0,
      limit : 10,
      name : filterName,
    }
    setPage(1)
    if (tabMenu === 'list-of-registered-member') fetchRegisteredNameList(id, true, filters)
    else fetchRegisteredNameList(id, false, filters)
  }
  const onClearSearch = () => {
    setfilterName('')
    const filters = {
      skip: 0,
      limit : 10,
    }
    setPage(1)
    if (tabMenu === 'list-of-registered-member') fetchRegisteredNameList(id, true, filters)
    else fetchRegisteredNameList(id, false, filters)
  }

  const loadExcel = () => {
    if(tabMenu == 'list-of-registered-member'){
      const filter = {
          skip: 0,
          limit: 10000
      }
      dispatch(getRegisteredNameList(id, true, filter)).then(({ type,data }) => {
          if(type.endsWith('_SUCCESS')){
              //alert(JSON.stringify(data))
              if(data?.length > 0){
                const org_code = data[0].org_code;
                if(org_code == '1000'){ //--- เฉพาะสภาวิศวกร
                  const resultToxlsx = data.map((v,index) => {
                      return {
                          "ลำดับ" : index + 1,
                          "ชือ-นามสกุล" : v.full_name,
                          "เลขที่สมาชิก" : v.user_no,
                          "ประเภทสมาชิก" : v.user_3rd_type,
                          "เบอร์โทร" : v.phone_no,
                          "Email" : v.email,
                          "ชื่อหน่วยงาน" : v.user_organization_name,
                          "วันที่ลงทะเบียนล่วงหน้า" : v.preregister_date ? DateTimeHHmmssDisplay(v.preregister_date) : '',
                          "การเข้าร่วมกิจกรรม" : v.status_name,
                          "วันที่เข้าร่วมกิจกรรมวันแรก" : v.date_check_in ? DateTimeHHmmssDisplay(v.date_check_in) : '',                          
                          "วันที่ Checkout วันแรก" : v.checkout_date ? DateTimeHHmmssDisplay(v.checkout_date) : '',
                          "วันที่เริ่มนับคะแนน" : v.effective_date ? DateTimeHHmmssDisplay(v.effective_date) : '',
                          "วันที่นําเข้าข้อมูล" : v.createdAt ? DateTimeHHmmssDisplay(v.createdAt) : '',
                          "จำนวนชั่วโมง" : v.received_hours,
                          "คะแนน CPD ที่ได้รับ" : v.point,
                          "สอบผ่าน/ไม่ผ่าน" : v.is_exam,
                          "หมายเหตุ" : v.remark,
                      };
                  });
                  const dataWS = XLSX.utils.json_to_sheet(resultToxlsx);
                  const wb = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(wb, dataWS);
                  XLSX.writeFile(wb, "ผู้ลงทะเบียนสมาชิกสภาวิศวกร.xlsx");
                }else{
                  const resultToxlsx = data.map((v,index) => {
                      return {
                          "ลำดับ" : index + 1,
                          "ชือ-นามสกุล" : v.full_name,
                          "เลขที่สมาชิก" : v.user_no,
                          "วันที่ลงทะเบียนล่วงหน้า" : v.preregister_date ? DateTimeHHmmssDisplay(v.preregister_date) : '',
                          "การเข้าร่วมกิจกรรม" : v.status_name,
                          "วันที่เข้าร่วมกิจกรรมวันแรก" : v.date_check_in ? DateTimeHHmmssDisplay(v.date_check_in) : '',
                          "วันที่ Checkout วันแรก" : v.checkout_date ? DateTimeHHmmssDisplay(v.checkout_date) : '',
                          "วันที่เริ่มนับคะแนน" : v.effective_date ? DateTimeHHmmssDisplay(v.effective_date) : '',
                          "วันที่นําเข้าข้อมูล" : v.createdAt ? DateTimeHHmmssDisplay(v.createdAt) : '',
                          "จำนวนชั่วโมง" : v.received_hours,
                          "คะแนน CPD ที่ได้รับ" : v.point,
                          "สอบผ่าน/ไม่ผ่าน" : v.is_exam,
                          "หมายเหตุ" : v.remark,
                      };
                  });
                  const dataWS = XLSX.utils.json_to_sheet(resultToxlsx);
                  const wb = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(wb, dataWS);
                  XLSX.writeFile(wb, "ผู้ลงทะเบียนสมาชิกสภาวิศวกร.xlsx");
                }
              }
          }
      })
    }else if(tabMenu == 'list-of-registered-normal'){

      const filter = {
          skip: 0,
          limit: 10000
      }
      dispatch(getRegisteredNameList(id, false, filter)).then(({ type,data }) => {
          if(type.endsWith('_SUCCESS')){
              //alert(JSON.stringify(data))
              if(data?.length > 0){
                const resultToxlsx = data.map((v,index) => {
                    return {
                        "ลำดับ" : index + 1,
                        "ชือ-นามสกุล" : v.full_name,
                        "ชื่อหน่วยงาน" : v.user_organization_name,
                        "เบอร์โทรศัพท์" : v.phone_no,
                        "Email" : v.email,
                        "วันที่ลงทะเบียน" : v.preregister_date ? DateTimeHHmmssDisplay(v.preregister_date) : '',
                        "วันที่เข้าร่วมกิจกรรมวันแรก" : v.date_check_in ? DateTimeHHmmssDisplay(v.date_check_in) : '',
                        "วันที่ Checkout วันแรก" : v.checkout_date ? DateTimeHHmmssDisplay(v.checkout_date) : '',
                        "วันที่นําเข้าข้อมูล" : v.createdAt ? DateTimeHHmmssDisplay(v.createdAt) : '',
                        "จำนวนชั่วโมง"  : v.received_hours,
                    };
                });
                const dataWS = XLSX.utils.json_to_sheet(resultToxlsx);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, dataWS);
                XLSX.writeFile(wb, "ผู้ลงทะเบียนบุคคลทั่วไป.xlsx");
              }
          }
      })


    }    

  }

  const ResentMail = () => {
    const data_to_api = {
      user_activity_point_id : DataResentMail.user_activity_point_id,
    };
    //dispatch(ApiExternalEventSentEmail(data_to_api))
    dispatch(ApiExternalEventSentEmail(data_to_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.code == 200){
          setSuccessModal(true)
        }else{
            setFailMessageModal({
              headline: 'ทำรายการไม่สำเร็จ',
              message: data_info.message,
            })
            setFailModal(true)
        }
      }else{
        setFailMessageModal({
          headline: 'internal server error',
          message: '',
        })
        setFailModal(true)
      }     
    })
  }


  const ConfirmResentMail = async (inp_obj) => {
    const request = {
      id_card_or_fullname: inp_obj.user_no,
    }
    const data = await dispatch(getMemberCOE(request))
    if (data) {
      const { member_no,full_name,email } = data
      setDataResentMail({
        user_activity_point_id : inp_obj.id,
        full_name : full_name,
        member_no : member_no,
        email : email
      })
      setIsModalConfirmMail(true)
    }
  }

  const gotoEventCompare = () => {
    navigate(`/CpdManage/EventCompareUser/${id}`)
  }
  const onClearfilterName = () => {
    setfilterName('');
  }
  return (
    <Div>
      <Box sx={{  mb: 2 }}>
        <div className="group_filter">
          <div className="css_filter_name">
            <Field className="field" label="วันที่จัดกิจกรรม*">
              <Date
                  className={'input-calendar'}
                  value={filterDate}
                  onChange={(v) => setfilterDate(v)}
              />
            </Field>
          </div>
          <div className="mr-1-rem">
            <SearchText
              placeholder="ค้นหาด้วยชื่อสมาชิก, เลขที่สมาชิก, หรือ Email"
              value={filterName}
              onChange={(e) => setfilterName(e.target.value)}
              onClear={onClearfilterName}
            />
          </div>
          <div className="css_button_filter mr-1-rem">
              <Button onClick={onSearch} >
                ค้นหา
              </Button>
          </div>
          <div className="css_button_filter mr-1-rem">
            <ButtonSecondary onClick={onClearSearch} >
              ล้างการค้นหา
            </ButtonSecondary>
          </div>
        </div>
      </Box>

      <Box sx={{ mb: 2 }}>
        <TabGroupMenu tabMenuOptions={tabMenuOptions} tabMenu={tabMenu} setTabMenu={toggleTab} />
      </Box>

      <TableWrapper>
        <Stack className="header-table" direction="row" justifyContent="space-between" alignItems="center">
          <Box>
            <Stack direction="row" spacing={1}>
              <div className="heading-table">จำนวนผู้ลงทะเบียนเข้าร่วมกิจกรรม</div>
              <Badge>{total} คน</Badge>
            </Stack>
            <div className="sub-heading-table">รายชื่อแสดงเฉพาะสมาชิกสภาวิศวกรเท่านั้น</div>
          </Box>

          <Box>
            <Stack direction="row" spacing={2}>
                {isOpenCompareUser ? (
                    <Button onClick={gotoEventCompare}>แม่ข่ายตรวจสอบรายชื่อ</Button>
                  ) : (
                    <div className="disable_compare">
                      <div className="font_bold">แม่ข่ายตรวจสอบรายชื่อ</div>
                      <div>(ใช้งานได้ ณ วันสิ้นสุดกิจกรรม)</div>
                    </div>
                  )
                }
                {(permission_CpdManageOrgActivity_Dowload_UserRegister?.canRead === true) && (
                  <ButtonOutline onClick={() => loadExcel()}>Download</ButtonOutline>
                )}
            </Stack>
          </Box>
          
        </Stack>
        {tabMenu === 'list-of-registered-member' ? (
          <Table
            data={registeredNames}
            columns={coeMemberColumns}
            page={page}
            pageCount={pageCount}
            onStateChange={onChangePagination}
          />
        ) : (
          <Table
            data={registeredNames}
            columns={normalMemberColumn}
            page={page}
            pageCount={pageCount}
            onStateChange={onChangePagination}
          />
        )}
      </TableWrapper>

      <DialogConfirmV2
        open={Boolean(IsModalConfirmMail)}
        onClose={() => setIsModalConfirmMail(false)}
        onNo={() => setIsModalConfirmMail(false)}
        onSubmit={() => {
          setIsModalConfirmMail(false)
          ResentMail()
        }}
        icon={warningIcon}
        title={'ยืนยันการส่งข้อความ ยืนยันรับสิทธิ์คะแนน CPD'}
        html={
          <div>
            <div>{DataResentMail.full_name}</div>
            <div>{'เลขที่สมาชิก '}{DataResentMail.member_no}</div>
            <div>{DataResentMail.email}</div>
            <div className="margin_top_16">{'(ระบบดึงข้อมูล Email ล่าสุดจาก Profile สมาชิก)'}</div>
          </div>
        }
        textYes='ยืนยัน'
        textNo='ยกเลิก'
      />

      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={() => {
          setSuccessModal(false)
        }}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes='ตกลง'
      />

      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => {
            setFailModal(false)
        }}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes='ตกลง'
      />
    </Div>
  )
}

export default RegisteredNameListPart
